/*Custom Buttons*/

.is-primary[disabled]{
  background: $black-two;
  color: $black-37;
}

.is-primary {
  /*background: $dodger-blue;*/
  //padding: 4px 24px 6px 25px;
  margin-right: 5px;
}

.is-primary:hover, .is-primary:hover {
/*  background: $primary-blue;*/
  color: $white;
}

button.is-cancel {
  background: $black-three;
  color: $white;
  border: 0px;
  &[disabled]{
    background-color: $black-dark;
  }
}


button.is-cancel:hover  {
  background:$black-two; 
  color:$white;
}
button.is-cancel:focus  {
  background:$black-two; 
  color:$white;
}


.is-custom-save {
  margin-right: 10px;
}

.is-fixed-size {
  width:100px;
  height: 33px;
}

.datepicker .calendar .calendar-nav .calendar-nav-left .button,
.datepicker .calendar .calendar-nav .calendar-nav-right .button {
  background-color: $primary;
  border: $primary;
}
