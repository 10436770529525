/* You can add global styles to this file, and also import other style files */
//@import "~font-awesome/css/font-awesome.css";
@import "./assets/scss/colors.scss";
@import "./assets/scss/bulma-variables.scss";
@import "./assets/scss/bulma-steps.sass";
@import "./assets/scss/bulma-overrides.scss";
@import "./assets/scss/display-message.scss";
@import "./assets/scss/forms.scss";
@import "./assets/scss/preview.scss";
@import "./assets/scss/buttons.scss";
@import "./assets/scss/tables.scss";
@import "./assets/scss/headings.scss";
@import "./assets/scss/tiles.scss";
@import "./assets/scss/horizontal-nav.scss";
@import "./assets/scss/error-messages.scss";
@import "./assets/scss/material-overrides.scss";



/* Overwrite bulma defaults - TODO move custom to custom.css */

* {
  padding:0px;
  margin:0px;
}

html,body{
  height:100%;
}
html {
  background: url(/assets/images/dark-blue-bg.png) no-repeat center center fixed;
	background-size: cover;
}



a {
  color: $mayoBlue;
}

.white {
  color: $white;
}

.content-middle {
  height: 100vh;
  background: $accentWhite;
  p {
    padding: 40px;
  }
}

.text-left {
  text-align: left;
  font-size: 0.9em;
}

.text-right {
  text-align: right !important;
  font-size: 0.9em;
  label {
    text-align: right !important;
  }
}

.is-centered{
  justify-content: center;
  text-align: center;
}

.is-clearfix{
  clear:both;
  display: block;
}
input:read-only {
  background-color: $greySugar;
}

.input.ng-touched {
  border:solid 1px $primary;

}
select.ng-touched {
  border:solid 1px $primary;

}

.input.ng-invalid,
.input.ng-dirty.ng-invalid {
  border:solid 1px $invalidData;

}
select.ng-invalid,
select.ng-dirty.ng-invalid {
  border:solid 1px $invalidData;

}


.hide-content {
  display: none;
}

.hide-content-space {
   visibility: hidden;
}


.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.element-disabled{
   opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

div.vertical-line{
  width: 0px; /* Use only border style */
  height: 100%;
  float: left;
  border: 1px inset; /* This is default border style for <hr> tag */
}

.study-header-bar {
  padding: 10px 0 0px 0;
}

.header-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input[type="search"], .textarea[type="search"]{
  border-radius:0px!important
}


.form-page {
  margin-top: 10px;
  background: $greySugar;

  .sub-menu {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    
  }

  ul {
    li {
     // background: $greyIvory;
      font-size: 0.9em;
    //  border-bottom: solid 1px $grey-light;

      a {
        display: block;
        padding: 15px 10px;
       // min-width: 250px;
        color: $text;

        &:hover {
          color: $accentBlue;
          background-color: whitesmoke;
        }

        &.is-active {
          background-color: whitesmoke;
          color: $accentBlue;
        }
      }
    }
  }
}

.form-content {
  background-color: $container-gray; //$greySugar2;
}


.form-content:nth-child(even) {
  background:$container-gray;// $greySugar !important;
}
//.form-content:nth-child(odd) {
 // background: $white ; //!important;
//}

.no-top-padding{
  padding-top:0px;
}

.no-bottom-padding{
  padding-bottom:0px;
}
.no-left-padding{
  padding-left:0px;
}

.no-right-padding{
  padding-right:0px;
}


.no-top-margin{
  margin-top:0px;
}

.no-bottom-margin{
  margin-bottom:0px;
}

.no-right-margin{
  margin-right:0px;
}


.is-read-only-label{
  font-size:14px;
}


.org-width {
  width:250px;
}

.ta-results {
  border:1px $greyBeryl solid;
  width:200px;
  
}
.button{
  justify-content: center;
  height: 2.25em;
}

.mdc-list-item__primary-text{       
  background-color:$white;
  border:none;
  width:300px;
  text-align: left;
  .has-padding{
     padding-left:40px;
  }
  &.active{
    background-color: $peacock-blue;
  }
}

.has-content-disabled{
  cursor:not-allowed;
  > * {
      pointer-events: none;
  }
  z-index:10;
  opacity: 0.6;
  transition: opacity .5s;
}

ng-multiselect-dropdown {
  .dropdown-list {
    min-height: 40vh;
  }

  .multiselect-dropdown {
    .selected-item-container>.selected-item {
      max-width: initial !important;
      margin-bottom: 5px;
    }
  }
}

.has-been-disabled{
  z-index:10;
  opacity: 0.6;
  transition: opacity .5s;
}

@media screen and (max-width: 999px) {
  .nav {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1000px) {
  .nav {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
  }



  .container {
    .is-fluid {
      margin: 0px !important;
    }
  }
}

.section-title,
.section-subtitle {
  color: $accentBlue;
  font-weight: 600;
}

.top-section-top-padding{
    padding-top:$top-padding;
}
/*End Overwrite*/


/*Layout CSS*/

.nav{
  background: rgba(255,255,255,0.8);
  height: 56px;
}

.no-pad {
  padding: 0px !important;
}

.nav-item .nav-logo {
  height: 55px;
  margin-right: 0.5rem;
}

.nav-title {
  font-size: 1.125rem;  // 17px
  color: $primary-blue;
  margin-top: 15px;
  margin-left: 10px;
  font-weight: bold;
}

.hamburgermenu-container {
  padding: 1.25rem;
  background-color: $light;
  margin-right: -10px;
}
.noMarginLeft{margin-left:0px;}
.noMarginRight{margin-right:0px;}

.has-no-left-margin{
  margin-left:0px;
}
.has-no-right-margin{
  margin-right:0px;
}

.is-fullheight {
  min-height: 100vh;
  margin-right: 0px;
}
#content-column {padding: 10px 0px 0px 0px;}
.section-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  font-weight: bold;
}

.section-subtitle {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.hamburger-menu {
  cursor: pointer;




  &:hover {
    background-color: $light;
  }
}

.container.is-fluid {
  margin: 0;
  padding-left:0px;
  padding-right: 0px;
}

.input, .textarea{
 /* max-width: 14em; */
  width: 100%;
}

.select select:focus, .select select.is-focused, .select select:active, .select select.is-active{
  border-color: $accentBlue;
}
.select:after {
  border: 1px solid $mayoBlue;
  border-right: none;
  border-top:none;
}

.form-fixed-width{
  .field-label {
      padding-top: 0.375em;
      width: 75px;
      display: table;
  }

   .field-body{
       flex-grow: 0;
   }

}
.left-menu {display: block !important;}
.is-menu-open-active span {
  background-color: $accentBlue;
}

#page-container {
  padding: 0;
  margin-top: 0;
  margin-right: .75em;
  margin-left: .75em;

  #sidebar {
    padding-left: 0;
    margin-left: -.75em;
  }

  > .column {
    padding-top: 0;
    margin-top: 0;
  }

}

.navbar {
  background-color: rgba(255, 255, 255, 0.8);
}

div.navbar-right > div.is-save{
    margin-top:10px;
}

.red-font {
  color:$red;
}

/*main content*/
@media screen and (max-width:768px) {
  body {
    min-width: 1280px;
  }
}
.switchVertical {
  display: -webkit-box !important;
  display: -ms-flexbox;
  display: flex;
  .preview-section{
    padding-top: 0.65rem;
    padding-left:3px;
  }
  

}
.form-page.is-vertical-open.switchToHorizontal {
    #show-preview-content{
      display: none;
    }
    .preview-section{
      width:5%; //10% ;
      left:96%; //90%
       padding-right:10px;
    }
}

.form-page.switchToHorizontal {
    // .preview-section{
    //   width:49%;
     
    //   left:50%; 
    // }
    .scrollNavBg {
      flex-basis: 30% ;
    }
    .scrollContainer {
      flex-basis:70%;
    }
}

.nav-return-bar {
  width:100%;
  height:38px;
  background-color: $white;
  a {
    line-height: 2;
  }
  .icon {
    padding-left:15px;
    &.has-left-icon{
      position: relative;
      top:3px;
    }
  }  
  .link-text {
    padding-left: 25px;
    font-weight: bold;
    font-size: 14px;
  }
}




  /* Applies a grabbing hand on top of the div that's draggable*/
  .cursor-draggable {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
 /*  Apply a "closed-hand" cursor during drag operation. */
.cursor-draggable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.cursor-draggable .ignore-draggable
{
    cursor: pointer;
}

.cursor-draggable:active .ignore-draggable
{
    cursor: pointer;
}

@media screen and (min-width:1440px) {
  .form-page .switchToHorizontal {
      .preview-section{
        width:50%;
      }
      .scrollNavBg {
        flex-basis:30% !important;
      }
      .scrollContainer {
        flex-basis:70% !important;
      }
      .form-main-title-header{
        flex-basis: 30%!important;
      }
    }

}
@media screen and (max-width: 1200px) {
  body.switchToHorizontal {
      .switchVertical{
        width:1280px;
      }
}
}


@media screen and (max-width: 1024px) {
  .form-fixed-width .field-body{
    -webkit-box-flex: 5;
    -ms-flex-positive:5;
    flex-grow: 5;
}
.form-fixed-width .input, .form-fixed-width select{
  width: 100%;
}
.form-fixed-width div.select {display: inherit;}
.form-page .scrollNavBg{
  flex-basis: 30% !important;
}
.form-page .scrollContainer{
  flex-basis: 70% !important;
}
.form-main-title-header{
  flex-basis: 30%!important;
}

#content-column{
  flex-basis: 80%;
}
}
@media screen and (min-width: 1024px) {
  .form-fixed-width .field-body{
  -webkit-box-flex: 1;
  -ms-flex-positive:1;
  flex-grow: 1;
  }
  .form-fixed-width .input, .form-fixed-width select{
    max-width: 14em;
    width: 200px;
  }
  .navbar-burger {
    display: block;
    border: none;
    width: 3em;
    padding-left: 3em;
  }
  .button.navbar-burger {
    background: transparent;
    &:focus {
      outline:0;
      border:none;
      box-shadow: none;
    }
  }



  .navbar-link::after {
    display: none;
  }
  .tooltip:hover,
  ::before,
  ::after,
  .tooltip:hover::before,
  .tooltip:hover::after {
    z-index: 10;
  }

  .navbar-dropdown.is-right {
    top: calc(100% + (-15px));
    margin-right: .75em;
  }
}

button, .input, .textarea, .select select, .file-cta, .file-name, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis{
  height:2.25em;
}

@media screen and (min-width: 779px){ .modal.is-active { display: -webkit-box; display: -ms-flexbox; display: inline-block; } .modal-card{ margin-top: 5%; } }
