

h1 {
    font-size: 2em;
    font-weight: bold;
}
h2 {
    font-size: 1.5em;
    font-weight: bold;
}

h3 {
  color: $dodger-blue;
  margin-left: 20px;
/*  margin-top: 1em;*/
  font-size: 16px;
  font-weight: bold;
}


.header-text{
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: $white;
}
