@import "./../../assets/scss/colors.scss";

$standard-margin:5px;

.reg-tiles{
  margin-bottom: $standard-margin;
    .reg-tile{
      margin-left: 40px; //97px;
      margin-right: 0px;
      margin-top: $standard-margin;
      margin-bottom: $standard-margin;
    }
}

.tile{
  position: relative;
  &.is-3{
    max-width:235px;
    .button{
      width:175px;
      font-weight: bold;
      font-size:14px;
    }
  }
  &.is-child{ 
    &.is-disabled{
       z-index:10;
       opacity: 0.6;
       transition: opacity .5s;
    }
    &.place-holder{
      opacity: 0.0;
    }
    
    &.box{ 
          height:274px;
          padding-top: 0px;
          padding-bottom: 10px;
          padding-left: 0px;
          padding-right: 0px;
          text-align:center;
      &.is-half-tile{
        height:120px; 
        & .is-subscribe{
          width:160px;
        }
        & .has-left-padding{
          padding-left:10px;
        }
      }    
    }    
    &.is-4{
      margin-right: 49px;
      margin-left: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px;
      width: 205px;
    }
  //   &.is-ancestor:not(:last-child){
  //   margin-bottom: $standard-margin;
  // }
    .content-div{
      height:182px;
    }
  }
 } 
.color-bar{
  width: 100%; height: 9px;
  border-style: solid;
  margin-bottom: 10px;
  &.green{
    border-color: $grass-green;
    background-color: $grass-green;
  }
  &.orange{
    border-color: $yellowish-orange;
    background-color: $yellowish-orange;
  }
  &.purple{
    border-color: $berry-purple;
    background-color: $berry-purple;
  }
  &.red{
    border-color: $blood-orange;
    background-color: $blood-orange;
  } 
  &.peacock-blue{
    border-color: $peacock-blue;
    background-color: $peacock-blue;
  }   
  &.grey{
    border-color: $greyBery2;
    background-color: $greyBery2;
  }   
}
