.section {
  background-color: transparent;
}

.control {
  font-size: .9rem
}

input {
  font-size: .9rem;
}

// .table:not(.is-bordered) td { border: none; }

// .table:not(.is-bordered)  th { border: none; }

.navbar-item img {
  max-height: 64px;
}

$timeline-line : 1em solid lime; //$grey-light;

$timeline-marker-background-color: yellow;
$timeline-marker-border	: .1em solid blue;

// Overidding bulma styles
.button.is-custom-save {
  background-color: $dodger-blue;
  border-color: transparent;
  color: #fff;
}

.button.is-primary {
  background-color: $primary-blue;
}

.button.is-primary[disabled],
fieldset[disabled] .button.is-primary {
  background-color: $primary-blue;
  border-color: $primary-blue;
}

.button.is-primary.is-hovered,
.button.is-primary:hover {
  background-color: $primary-blue;
}

.button.is-primary.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-outlined {
  background-color: transparent;
  border-color: $primary-blue;
  box-shadow: none;
  color: $primary-blue;
}

.select:not(.is-multiple) {
  height: 2.25em;
}

.menu-list a.is-active {
  background-color: #3273dc;
}

.column.is-narrow {
  padding-top: 1rem
}

.select select {
  padding-top: calc(0.375em - 1px);
  padding-bottom: calc(0.375em - 1px);
}

.navbar-link {
  padding-right: 2.5em;
}

.notification.is-link {
  background-color: #f5f5f5;
  color: #4a4a4a;
}