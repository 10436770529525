@import "./../../assets/scss/colors.scss";



.error-message-block{
  position: absolute;
  left: 36%;
  top: 5%;
  font-size: 12px;
  z-index: 30;
}
.is-error-row {
    border:1px solid $invalidData;
}
.is-error-cell{
    width:3px;
    a {
        color:$invalidData;
    }
    .has-error-indicator{
        position:absolute;
    }
    .event-error{
        z-index:6;
        position:absolute;
        .has-delete-icon{
            position: relative;
            height: 1px;
            top: -15px;
            right:15px;
           // right: -360px;
        }
    }
}

.has-show-link, .error-indicator {
    color:$invalidData;
}