span.sort{
    vertical-align: top;
}
// span.sort .fa{
//     font-size: 14px;
// }


// this should be handled by the buma variable $table-row-active-background-color 
// but it stopped working when we switched to release 0.6.1
.table {
    font-size:14px;
    tr.is-selected  {
     background-color: $primary;
    }
}
.table.is-borderless {
    td {
        border:none;
    }
    th{
        border:none;
    }
}

.study-setup-component {
    & .table tr select, input[type=text]{    
        background-color: $grey-table-edit;    
        border: solid 1.5px $greySugar2;    
    }
}