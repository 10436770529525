


.scrollNavBg{
  border-right: 1px solid $grey-light;
}

.scrollContainer {
  overflow-x: hidden;
  position: relative;
  width: calc(100% - 250px);
  height: 60vh;
  background: $greySugar2;
  padding: 10px 20px;
  flex-basis: 80%;
  flex-grow: 0;
  .label {
    font-weight: 600;
    line-height: 3em;
  }
}

.form-content:nth-child(even) {
  background: $greySugar2;
}
.form-content:nth-child(odd) {
  background: $greySugar;
}
.input:focus, .input.is-focused, .input:active, .input.is-active, .textarea:focus,
.textarea.is-focused, .textarea:active, .textarea.is-active {
  border-color: $accentBlue;
}
.sub{
  input{width: 12em;}
}


.form-main-title-header{
    flex-basis: 20%;
    flex-grow: 0;
}
.form-main-title-header > h3{
    padding-left:20px;
}

.form-table-container{
  padding-top:5px;
  &.study-setup-content{
    min-height:67vh; //TODO: when preview is added, make it 88vh
    overflow-x:auto;
  }
}

/* Clearable text inputs */
.clearIcon{
  color:hsl(0, 100%, 50%);
  position: absolute;
  cursor:pointer;
  right:20px;
  display: inline-block;
  font-weight: bold;
}
.clearIcon-hide{
  display: none;
}

$pull-out-tab-height:64px;

.pull-out-variable-content{
  width:750px;
  height:490px;
  position:relative;
  left:-25px;
  .tabs {
    height:$pull-out-tab-height;
    margin-bottom:5px;  
    ul{
      border:0px;
    }
    li, a{
      font-size:16px;
    }
    li.is-active a {     
      border-bottom-width: 5px;
      border-bottom-color:$dodger-blue;
    }

    // li:not(.is-active)> a{          
    //   border:0px;
    // }     
  } 
  .tab-content{
    padding:0px 15px 0px 15px;
    .has-margin-right-15{
      margin-right:15px;
    }
    label{
      font-size:14px;
    }
    .has-class-list{
      width:185px;
      select{
        width:185px;
      }
    }
  } 

 
}
