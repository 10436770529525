$submenu-width:270px;
$height:500px;

.nav-bar-style {
    height:54px;
    border-bottom:solid 1px $grey-light;
}
.nav-bar-style > a > div {
    padding:10px;
    height:40px;
}
.nav-bar-style > a > div > span {
    padding-left:20px;
}

.nav-return-bar {
    .link-text{
        color:$primary-blue;
    }
    .has-link{
        width:$submenu-width;
        a{
          padding-left:0px;
           .icon{
              padding-left:0px;
              margin-left:-15px;
           }
        }
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;     
    }    
    li {
        float: left;
    }
    
    li a {
        margin-top:3px;
        display: block;       
        text-align: center;
        padding: 0px 16px 0px 16px!important;   
        font-weight: bold; 
        font-size:14px;  
        color:$mayoBlue;
        &.is-active, &.is-nav-active{
            color:$dodger-blue;
            border-bottom: 0.4em solid $dodger-blue;
        }

    }
}