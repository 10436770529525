$preview-top-overlay: -150px !default;

.is-main-section-vertical {
  padding-right: 0px;
  padding-top: 0px;
}

.preview-section {

  .preview-block {
    width: 100%;
    left: 0px;
    padding: 0px;
    position: relative;

    &.is-horizontal-collapsed {
      height: 39px; //50px;
      top: 4px;
    }

    &.is-vertical-collapsed {
      margin-top: 0px;
    }

    .preview-content-width-toggle {
      width: 10% !important;
    }
  }

  .preview-content {
    padding: 4px;
    width: 100%;
    position: absolute;

    // svg {
    //   vertical-align: baseline;
    //   padding-right: 4px;
    // }
  }

  .preview-content-vertical {
    // padding: 4px;
    height: 100%;

    // position: absolute;
    .vertical-pdf,
    .vertical-excel {
      position: relative;
    }

    // svg {
    //   vertical-align: baseline;
    //   padding-right: 4px;
    // }
  }

  .show-content>div {
    overflow-y: auto;
    height: 46vh;
  }

  .show-preview-content {
    //  padding: 10px 10px 10px 10px;
    background: $previewBg;
    margin: 0px 0px;

    &.is-collapsed-height {
      height: 8vh;
    }
  }

  .preview-bar {
    box-shadow: 4px 0px 6px $previewShadow;
    width: 100%;
    height: 35px;
    position: absolute;
    background: $previewBg;
  }

  .preview-bar-vertical {
    box-shadow: 4px 0px 6px $previewShadow;
    width: 35px;
    height: 100%;
    position: absolute;
    background: $previewBg;
  }

  .preview-button {
    text-align: center;
    cursor: pointer;
    background: $previewBg;
    padding: 4px 60px 4px 60px;
    border-radius: 2px;
    position: absolute;
    top: 1px; //12px;

    // border-top-left-radius: 0px;
    // border-top-right-radius: 0px;
    line-height: 54px;
    color: $greyFiord;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    //    left: calc(50% - 125px);
    box-shadow: 4px 14px 10px -8px $previewShadow;

    .preview-btn-text {
      font-weight: bold;
    }
  }

  .preview-button-vertical {
    text-align: center;
    cursor: pointer;
    background: #f7e1bc;
    border-radius: 2px;
    position: absolute;
    margin: auto;
    width: 35px; //50px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    line-height: 30px; // 60px;
    color: #53575a;
    top: 35vh; //calc(50% - 133px);
    height: 25vh; //250px;
    box-shadow: 10px 0px 10px -8px $previewShadow;
    left: 0px;
    padding: 65px 0px 34px 0px;

    // i
    // {
    //   transform: rotate(-90deg);
    // }
    .preview-btn-text {
      transform: rotate(-90deg);
      font-weight: bold;
      width: 100px;
      height: 106px;
      white-space: nowrap;


    }
  }
  .preview-list-content.preview-vertical {
    .show-content>div {
      overflow-y: auto;
      height: 65vh;
    }
  }

  #switch-structure-btn {
    background: white;
    padding: 4px;
    border: 1px solid black;
    border-radius: 4px;
    position: absolute;
    top: 4px;
    right: 2px;
    height: 30px;
    width: 30px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;

    i {
      padding: 0px;

    }
  }


  #switch-structure-btn-vertical {
    top: 0.1vh;
    background: white;
    padding: 4px;
    border: 1px solid black;
    border-radius: 4px;
    position: absolute;
    bottom: 4px;
    left: 2px;
    height: 30px;
    width: 30px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;

    i {
      padding: 0px;

    }
  }


  #preview-btn {

    .pdf-btn,
    .excel-btn {
      background: white;
      padding: 4px;
      border: 1px solid black;
      border-radius: 4px;
      position: absolute;

      right: 40px;
      height: 30px;
      width: 30px;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        padding: 0px;
        margin-left: 5px;
      }
    }
  }

  #preview-content-vertical-btn {
    top: 4vh;

    .pdf-btn,
    .excel-btn {
      background: white;
      padding: 4px;
      border: 1px solid black;
      border-radius: 4px;
      position: absolute;
      bottom: -70px;
      left: 2px;
      height: 30px;
      width: 30px;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;

      // svg{
      //   padding:0px;
      //   margin-left:5px;
      // }
    }
  }


  .preview-title {
    text-align: center;
    border-bottom: 1px solid $greyFeta;
    padding: 8px 0 20px 0;
    margin-bottom: 20px;
    font-weight: 600;
    margin-top: 20px;
  }

  .preview-sec-title {
    color: $accentBlue;
    font-weight: bold;
  }

  .preview-sec-labels {
    margin: 10px 0 0 25px;
    font-size: 0.9em;
  }

  .preview-label-question {
    min-width: 50%;
    padding-right: 45px;
    display: inline-block;
  }

  .preview-label-value {
    font-weight: bold;
  }

  #preview-arrow {
    font-size: 1em;
  }

  #preview-arrow-vertical {
    font-size: 1em;
  }

  .preview-list-content {
    border: 1px solid $previewBg;
    background: $accentWhite;
    color: $greyFiord;
    //padding: 30px 0 30px 0;
    //margin: 20px;
    //    padding-top: 30px;
    //  padding-bottom:30px;
    //    margin-top:20px;
    margin-right: 30px;
    // margin-bottom:20px;
    margin-left: 30px;
    box-shadow: 6px 6px 14px $greyTapa;
    // overflow-y: scroll;
    //height: calc(50vh - 60px); // calc(100vh - 80px) ;

    .label {
      font-size: 0.9em;
      font-weight: normal !important;
      margin-top: 0em !important;
    }

    .text {
      font-size: 0.9em;
    }

    .content {
      font-size: 0.9em;
      font-weight: bold;
    }
  }

  h4 {
    border-top: 1px solid $greyHarp;
    padding: 10px 0px 0px 0px;
    margin: 10px 0px 0px 0px;
  }

  .noBorder {
    border: none;
    padding: 0px;
    margin: 20px 0px;
  }

  ul {
    list-style: none;
    margin: 0;
    // li:before {
    //   content: '✓';
    // }
  }
}


.switchToHorizontal {
  &.is-vertical-open {
    width: 100%; //93%;
  }
}

.switchVertical {
  display: -webkit-box !important;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  height: calc(100vh - 115px) !important;
}

.preview-vertical-expanded {
  left: 50%;
}

//.preview-vertical-margin-left
//{ 
// left:12px ;
//}
.preview-vertical {
  margin-top: 0px;
  height: calc(100vh - 115px) !important;
  margin-right: 20px !important;
}

// .preview-vertical-full-height
// {
// //  height: calc(100vh - 142px) !important;
//   height: calc(100vh - 115px) !important;
// }

.preview-list-content-toggle-height {
  height: 6vh !important;
}

.preview-vertical-width-toggle {
  min-width: 0px !important;
}

.togglePerviewOverlay {
  // width: calc(100vw - 64px);
  position: relative;
  z-index: 9;
  bottom: 0px;
  position: relative;
  top: $preview-top-overlay;
  height: 40vh;
}

.togglePerviewOverlayHeight {
  height: 64vh !important;
}

.preview-control {
  margin-top: auto;
}

.h1 {
  font-size: 2em;
  font-weight: bold;
}

.h2 {
  font-size: 1.5em;
  font-weight: bold;
}