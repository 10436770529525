/* @import "~bulma"; */
/*
Mayo Brand Colors - MayoBlue colors from brand guide "Provider-app-PS-styleguide-2015-02-25.pdf"
and other color names from http://www.htmlcsscolor.com/hex/B9BAB0
*/
//@import "~bulma/sass/utilities/initial-variables.sass";
@import "bulma/sass/utilities/_all.sass";

$navyBlue:          #061d48;
/*
11/14/2017 - https://www.evernote.com/shard/s13/sh/ae0e7065-d0d4-4dc2-b27d-a8116afdd1f9/5159f4a53193ef83
Note the $primary and $primary-invert variables.
Bulma uses a nauseating sea green color as it's primary.
Throughout the Mayo App user interface, I would like to use a 'Mayo Blue'
 for our primary color: #3399FF (or: R:51, G:153, B: 255).
*/

$primary-blue:      #003da5;
$azure-blue:         #008CFF;
$dodger-blue:        #3399FF;

$mayoBlue:          $primary-blue;  // to be replaced by $primary-blue
$mayoBlue80:        #3162b7;
$mayoBlue70:        #4b77c0;
$mayoBlue60:        #6689c8;
$mayoBlue50:        #7e9ed2;
$mayoBlue40:        #99b0db;
$mayoBlue20:        #ccd7ec;
$mayoBlue10:        #e4ebf6;
$buttonCustomBlue:  $dodger-blue ;

$black:             #111111;
$black-two:         #0E0E0E;
$black-three:       #0D0D0D;
$black-37:          #000000 37%;
$black-dark:        #040404;

$grey:              #363636;
$greyFiord:         #53575a;
$greyTapa:          #7d7e74;
$greyBeryl:         #999999;/*b9bab0 */
$greyBery2:         #797979;/*b9bab0 */
$greyHarp:          #cecfc7;
$greyFeta:          #dcddd7;
$greySugar:         #eaeae7;
$greySugar2:        #efedee; /*f0f0ee*/
$greyIvory:         #e2e0e1; /*f8f8f7 */
$greyDarker:        #323232;


$text:              #4a4a4a;
$text-strong	:   $greyDarker;
$grey-light:        #d6d4d4;
$grey-spacer :      #e0e0e0;
$grey-add :         #c1c1c1;
$grey-lighter:         #c2c2c2;
$grey-table-edit :  #F8F7F7;
$grey-edit-border: #8e8e8e;
$grey-sub-menu  :#e4e4e4;
$grey-sub-menu-border :#bcbcbc;
$left-menu-gray : #e2e1e1;
$container-gray: #efeded;

$accentGreen:       #43af2a;
$accentViolet:      #c22c8b;
$accentBlue:        #2492fa;
$accentTangerine:   #f8a11b;
$accentWhite:       #ffffff;
$accentOrange:      #fd5000;

$event-picker-odd:  #F2F2F2;
$event-picker-even:#F7F7F7;
$event-read-header: #A7A7A7;
$field-picker-add : #a2a2a2 ;

$disabled-font-color : #aeaeae;
/*Custom Color*/
$white:             $accentWhite;
$white-bis:         #fafafa;
$white-ter:         #f5f5f5;
$white-two:         $white;
$white-off:         #f9f9f9;
$white-off:         #f9f9f9;
$previewBg:         #f8e0bf;
$previewShadow:     #d0aa6c;
$invalidData:       #EC210B;
$tableHeader:       #003EA5;
$callout-border :   #f50c1c;

$dark-gray-odd-row : #bebebe;
$dark-gray-even-row:#bababa;



$semi-black-box-shadow: rgba(0,0,0,0.75);
$small-box-shadow: 0px 9px 19px 0px $semi-black-box-shadow;

$button-text-blue:  #003FA5;

/* Colors for title header bars*/
$grass-green:       #43B02A;
$blood-orange:      #FE5000;
$berry-purple:      #C22D8C;
$yellowish-orange:  #F9A21B;
$peacock-blue : #14b1e5;

$transparent-overlay: rgba(17, 17, 17, 0.22) ;
$shadow-box: rgba(0, 0, 0, .25);
$semi-black : rgb(219, 221, 224); /* Black w/opacity */

$successGreen:   #33cc66;
$warningYellow:#ffcc66;
$errorRed:#ff3366;
$error-background-red : #fff5f7;

$top-padding: 10px;
/*  Table Styles - 11/14/2017 */
$table-color : $grey-darker ;
$table-background-color :$white ;
$table-cell-border :1px solid $grey-lighter ;
$table-cell-border-width :0 0 1px ;
$table-cell-padding :0.5em 0.75em ;
$table-cell-heading-color : $text-strong ;
$table-head-color : $grey ;
$table-head-cell-border-width : 0 0 2px ;
$table-head-cell-color : $text-strong ;
$table-foot-cell-border-width : 2px 0 0 ;
$table-foot-cell-color : $text-strong ;
$table-row-hover-background-color : $white-bis ;
$table-row-active-background-color : $primary ;
$table-row-active-color : $primary-invert ;
$table-striped-row-even-background-color : $white-bis ;
$table-striped-row-even-hover-background-color : $white-ter ;
$table-row-even-background : $white-off ;
$table-background : $white-two;

//11/14/2017
//https://www.evernote.com/shard/s13/sh/7d0eb97f-d3df-4150-a64f-2ef4a9ea52fb/ba75c8e52c317911
$primary:           $mayoBlue;
$primary-invert:    findColorInvert($primary);
$success:           $successGreen;
$success-invert:    findColorInvert($success);
$warning:           $warningYellow;
$warning-invert:    findColorInvert($warning);
$danger :           $errorRed;
$danger-invert: findColorInvert($danger);
$customSave :       $buttonCustomBlue;
$customSave-invert : findColorInvert($customSave);
$modalWarning :       $blood-orange;
$modalWarning-invert : findColorInvert($blood-orange);



$red:            #dc3545;
$danger-invert:     findColorInvert($red);

$addColors: (
    "custom-save":($customSave, $customSave-invert),
    "modal-warn":($modalWarning, $modalWarning),
  );


$colors: (
    primary: ($primary, $primary-invert),
    info: ($info, $info-invert),
    success: ($success, $success-invert),
    warning: ($warning, $warning-invert),
    danger: ($danger, $danger-invert),

);

 $colors: map-merge($colors, $addColors);
