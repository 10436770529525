@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// (v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
// @include mat.all-legacy-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//@at-root$mayo-app-primary: mat-palette($mat-indigo);

$md-mcgpalette0: (
    50 : #e0e8f4,
    100 : #b3c5e4,
    200 : #809ed2,
    300 : #4d77c0,
    400 : #265ab3,
    500 : #003da5,
    600 : #00379d,
    700 : #002f93,
    800 : #00278a,
    900 : #001a79,
    A100 : #a7b2ff,
    A200 : #7486ff,
    A400 : #415aff,
    A700 : #2744ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Specific component overrides
.mat-mdc-tooltip.tooltip {
    font-size: .75rem !important;
    max-width: unset !important;
}




$mayo-app-primary: mat.define-palette($md-mcgpalette0);
$mayo-app-accent: mat.define-palette($md-mcgpalette0, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mayo-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$mayo-app-theme: mat.define-light-theme((color: (primary: $mayo-app-primary,
                accent: $mayo-app-accent,
                warn: $mayo-app-warn,
            ),
            typography: mat.define-typography-config(),
            density: 0,
        ));
//$mayo-app-theme: mat.define-light-theme($mayo-app-primary, $mayo-app-accent, $mayo-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mayo-app-theme);